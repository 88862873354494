import React from "react"
import { PageWrapper } from "~components/Core"

const header = {
  headerClasses: "site-header site-header--menu-start dark-header site-header--sticky",
  containerFluid:true,
  // customLogo: Images.HeaderLogo,
 
}

export default function Marketing() {
  return (
    <PageWrapper headerConfig={header}>
     
      <h2 className="typography__Heading-fvecw2-0 style__Title-sc-372oxm-1 hipacl gZXJhI text-center mt-100">Drones</h2>
      
    </PageWrapper>
  )
}
